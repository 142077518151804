.groupMode {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: #f4f6fc;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
}

.groupMode .topSection {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.groupMode .topSection .left {
  /* background-color: red; */
  height: 100%;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.groupMode .topSection .left .turns {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.groupMode .topSection .left .turns .turn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.groupMode .topSection .center {
  /* background-color: green; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.groupMode .topSection .right {
  /* background-color: blue; */
  height: 100%;

  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.groupMode .topSection .right .turns {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.groupMode .topSection .right .turns .turn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.groupMode .bottomSection {
  display: grid;
  /* grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr; */

  grid-template-columns: 1fr 1fr;

  height: 100%;
  /* background-color: purple; */
  gap: 20px;
  overflow: hidden;
}

.groupMode .bottomSection .countdownTimer {
  width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  position: relative;

  transition: all 0.2s ease-in-out;
}

.groupMode h1 {
  /* font-size: 7rem; */
  font-size: 7vw;
  color: #1b1d28;
  font-weight: 600;
}

.groupMode .bottomSection .groupName {
  position: absolute;
  top: 0px;

  /* width: ; */

  /* height: 50px; */
}

.hideMe {
  display: none !important;
}

.colorGreen {
  background-color: #00b050 !important;
}

.colorChnage {
  height: 100%;
  width: 100%;
  animation: backgroundChange 2s infinite;
}

@keyframes backgroundChange {
  0% {
    background: rgb(255, 255, 255);
  }

  50% {
    background: rgb(255, 192, 0);
  }

  100% {
    background: rbg(255, 255, 255);
  }
}
/* @keyframes backgroundChange {
  0% {
    background: rgb(255, 0, 0);
  }

  16.6% {
    background: rgb(255, 0, 255);
  }

  33.3% {
    background: rgb(0, 0, 255);
  }

  50% {
    background: rgb(0, 255, 255);
  }

  66.6% {
    background: rgb(0, 255, 0);
  }

  83.3% {
    background: rgb(255, 255, 0);
  }

  100% {
    background: rbg(255, 0, 0);
  }
} */
