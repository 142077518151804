html {
  scroll-behavior: smooth;
  scroll-padding-top: 2.5rem;
}

* {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  font-size: 16px;
}

.navLink {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0px;
  margin: 0px;
  /* font-family: "Roboto", sans-serif; */
}

h2 {
  font-weight: 600;
}
h3 {
  font-weight: 600;
}

p {
  font-weight: 400;
  font-size: 0.85rem;
}

.pac-container {
  z-index: 9999 !important;
}

input {
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
